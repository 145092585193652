import React from "react";
import axios from 'axios';

export default class PageNotFound extends React.Component {
    state = {
        pun: ""
    }

    componentDidMount() {
        axios.get(`https://icanhazdadjoke.com/`, {headers: {'Accept': 'text/plain'}})
            .then(res => {
                const pun = res.data;
                this.setState({ pun });
            })
    }

    render() {
        return (
            <div className="null-screen">
                <div className="no-nav-bar">
                    <h1>Well this is awkward....</h1>
                    <p>
                        The page you&#39;re looking for can&#39;t be found. You weren&#39;t supposed to end up here.
                    </p>
                    <p>
                        However, now that you are, here's a dad joke coz why not.
                    </p>
                    <h3>
                    "{ this.state.pun}"
                     </h3>
                </div>
            </div>
        )
    }
}
